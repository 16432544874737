import React, { useState } from "react"
import Layout from "../../components/layout"
import { useWindowSize } from "../../components/size-hook"
import Slider from "react-slick"

import background from "../../images/service.svg"

import evAnyImg from "../../images/solution/ev-any.svg"
import farmImg from "../../images/solution/farm.svg"
import fintechImg from "../../images/solution/fintech.svg"
import sabaImg from "../../images/solution/saba.svg"
import coinsImg from "../../images/solution/coins.svg"
import commerceImg from "../../images/solution/ecommerce.svg"

import evAnyGif from "../../images/solution/ev-any.gif"
import farmManagementGif from "../../images/solution/farm.gif"
import moooCoinsGif from "../../images/solution/coins.gif"
import sabaGif from "../../images/solution/saba.gif"
import fintechGif from "../../images/solution/fintech.gif"
import commerceGif from "../../images/solution/commerce.gif"

import nextArrow from "../../images/next.svg"
import prevArrow from "../../images/back.svg"

import "../../styles/solution.css"

const SolutionPage = () => {
  const [value, setValue] = useState(1)
  const size = useWindowSize()
  const solutionOptions = [
    {
      value: 1,
      header: "E-Dairy Mitra",
      backImage: evAnyGif,
      points: [
        "Connect with an Expert Veterinarian via audio/video calls and chat, similar to a Whatsapp interface",
        "Schedule a certified Veterinarian visit at your doorstep using an Uber-like Model",
        "Get affordable and transparent pricing for all veterinary services",
      ],
      contentImg: evAnyImg,
    },
    {
      value: 2,
      header: "MoooFarm Saba",
      backImage: sabaGif,
      points: [
        "Learn how to manage cattle health, nutrition, breeding, dairy farm, through e-learning videos created by experts ",
        "Access to important information such as loans, schemes, latest news and subsidies for farming ",
        "Connect with other dairy farmers to buy & sell cattle and exchange knowledge & other inputs to make dairy profitable",
        "Discover profitable techniques of dairy farming via webinars & panel discussions by progressive dairy farmers, experienced veterinarians and subject matter experts.",
      ],
      contentImg: sabaImg,
    },
    {
      value: 3,
      header: "Digital Livestock Management",
      backImage: farmManagementGif,
      points: [
        "Record and maintain the lifeline of each cattle through our digital cattle registry,",
        "Manage your revenues and expenses using easy and Farmer-friendly digital 'Bahi Khaata’",
        "Get predictive analytics for dairy farm management",
      ],
      contentImg: farmImg,
    },
    {
      value: 4,
      header: "MoooCoins",
      backImage: moooCoinsGif,
      points: [
        "Earn MoooCoins on every data input, product purchased and veterinary service booked through the app",
        "Redeem MoooCoins to get exciting vouchers and discounts on in-app services",
        "Compete with your peers and earn rewards through our gamified leaderboard.",
      ],
      contentImg: coinsImg,
    },
    {
      value: 5,
      header: "E-commerce",
      backImage: commerceGif,
      points: [
        "Purchase high-quality dairy farming inputs delivered at doorstep at an affordable price",
        "Easy online payment options including cash on delivery",
        "Superior customer support for product related queries",
      ],
      contentImg: commerceImg,
    },
    {
      value: 6,
      header: "Fin-Tech",
      backImage: fintechGif,
      points: [
        "Get instant access to credit from the comfort of your home",
        "Access to Hassle Free and affordable cattle insurance services",
        "Easy premium collection and repayment options available",
      ],
      contentImg: fintechImg,
    },
  ]

  const getObjFromValue = value => {
    let result = {}
    solutionOptions.forEach(option => {
      if (option.value === value) {
        result = option
      }
    })
    return result
  }
  const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "solution-slider-dots",
    nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />,
  }
  const solutionObj = getObjFromValue(value)
  return (
    <Layout>
      <div className="solution-container">
        {size.width > 1024 ? (
          <div className="solution-wrapper">
            <div className="solution-headers-wrapper">
              {solutionOptions.map(solution => (
                <div
                  key={solution.value}
                  className={`solution-header 
                        ${
                          value === solution.value
                            ? "solution-active-header"
                            : ""
                        }`}
                  onClick={() => setValue(solution.value)}
                >
                  <div
                    className={`${
                      value === solution.value
                        ? "solution-active-header-line"
                        : ""
                    }`}
                  ></div>
                  {solution.header}
                </div>
              ))}
            </div>
            <div className="solution-image-wrapper">
              <div className="solution-image-inner-wrapper">
                <div className="solution-image-background">
                  <img src={background} alt="background" />
                </div>
                <div
                  className="sdcw-image"
                  style={{
                    backgroundImage: `url(${solutionObj.backImage})`,
                  }}
                ></div>
              </div>
              {/*<div className="coming-soon-banner coming-margin">*/}
              {/*  Coming soon*/}
              {/*</div>*/}
            </div>
            <div className="solution-dynamic-content-wrapper">
              {solutionObj ? (
                <div className="sdcw-wrapper">
                  <div className="sdcw-content">
                    <div className="sdcw-content-image">
                      <img src={solutionObj.contentImg} alt="ev-anytime" />
                    </div>
                    <div className="sdcw-content-desc">
                      <ul>
                        {solutionObj.points &&
                          solutionObj.points.map(point => <li>{point}</li>)}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="solution-slider-wrapper">
            <Slider {...sliderSettings}>
              {solutionOptions.map((solution, index) => (
                <div key={solution.value} className="solution-slider-indi-div">
                  <div>
                    <div
                      className="sdcw-mobile-image"
                      style={{
                        backgroundImage: `url(${solution.backImage})`,
                      }}
                    ></div>
                  </div>
                  <div className="solution-slider-heading">
                    {solution.header}
                  </div>
                  {/*<div className="coming-soon-banner">*/}
                  {/*  Coming soon*/}
                  {/*</div>*/}
                  <div className="solution-dynamic-mobile-content-wrapper">
                    <div className="sdcw-wrapper">
                      <div className="sdcw-content">
                        <div className="sdcw-content-image">
                          <img src={solution.contentImg} alt="ev-anytime" />
                        </div>
                        <div className="sdcw-content-desc">
                          <ul>
                            {solution.points &&
                              solution.points.map(point => <li>{point}</li>)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SolutionPage
